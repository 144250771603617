.data-table{
  width: 100%;
  text-align: left;
  border-spacing: 0px;
  border-collapse: collapse;
}
.data-table tbody tr{
  cursor: pointer;
}
.data-table tbody tr:hover{
  background-color: #f4f4f5;
}
.data-table tbody tr.row-disabled{
  animation: rowDelete 0.5s ease-in-out forwards;
  background-color: rgba(255, 0, 0, 0.1) !important;
}
.data-table .team-table__choose {
    width: 35px;
}
.data-table tr th{
  color: #626E77;
  font-size: 12px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}
@media(max-width: 991px){
  .data-table tr th{
    width: auto !important;
  }
}
.data-table tr{
  position: relative;
  height: 48px;
}
.data-table tr::after{
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 30px);
  height: 1px;
  background-color: #EDEDED;
}
.data-table tr td
{
  color: #000;
  font-size: 14px;
}
.data-table tr th,
.data-table tr td{
  padding: 10px 5px;
}
.data-table tr th,
.data-table tr td
{
  padding: 11px 0;
}
.data-table tr.hightlight td:first-child{
  font-weight: 600;
}
@media(min-width: 992px){
  .data-table tr th,
  .data-table tr td
  {
    padding: 10.5px 15px;
  }
}
.data-table tr.disabled td{
  color: #808A8E;
}
.data-table tr.disabled .catalog-table__control{
  opacity: 1;
}
.catalog-table__name-hightlight{
  font-weight: 700;
}
.data-table__control-inner{
  display: flex;
  align-items: center;
  margin-left: auto;
}
.data-table__hide{
  position: relative;
  cursor: pointer;
}
.data-table__hide-icon{
  width: 18px;
  height: 16px;
  fill: #808a8e;
  display: block;
  margin-right: 10px;
}
.data-table__hide-content{
  display: none;
  padding: 5px 12px;
  border: 1px solid rgba(0, 0, 0, 0.07);
  position: absolute;
  left: auto;
  right: 0;
  top: calc(100% + 5px);
  z-index: 10;
  background-color: #fff;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
  white-space: nowrap;
  border-radius: 5px;
  color: #000;
}
.data-table__hide:hover .data-table__hide-content{
  display: block;
}
.data-mobile__wgt{
  padding: 10px 0;
  position: relative;
}
.data-mobile__wgt::before{
  content: "";
  position: absolute;
  left: -10px;
  top: 0;
  width: calc(100% + 20px);
  height: 1px;
  background-color: #ededed;
}
.data-mobile__wgt:last-child::after{
  content: "";
  position: absolute;
  left: -10px;
  bottom: 0;
  width: calc(100% + 20px);
  height: 1px;
  background-color: #ededed;
}
.data-mobile__wgt-content{
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.data-mobile__wgt-name{
  width: 100%;
  font-size: 14px;
  font-weight: bold;
  color: #000;
  padding-bottom: 5px;
  display: flex;
  justify-content: space-between;
}
.data-mobile__wgt-col{
  width: 50%;
  flex: 0 0 auto;
  font-size: 14px;
  font-weight: normal;
  color: #808a8e;
}
.data-mobile__wgt-col:nth-child(odd){
  display: flex;
  justify-content: flex-end;
}
.data-mobile-block__wgt{
  border: 1px solid #dde1e4;
  border-radius: 5px;
  padding: 15px;
  margin-bottom: 5px;
}
.data-mobile-block__wgt-inner{
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}
.data-mobile-block__wgt-header{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10px;
  width: 100%;
}
.data-mobile-block__wgt-name{
  font-size: 16px;
  color: #364ed4;
}
.data-mobile-block__wgt-content-wgt{
  width: 50%;
  padding-bottom: 10px;
}
.data-mobile-block__wgt-content-wgt:nth-child(even) .question-wgt__content-wgt-value{
  font-weight: 600;
}
.data-mobile-block__wgt-content-wgt:nth-child(odd){
  text-align: right;
}
.data-mobile-block__wgt-content-wgt-label{
  font-size: 12px;
  color: #626e77;
  margin-bottom: 5px;
}
.question-wgt__wgt-content-wgt-value{
  font-size: 14px;
  color: #000;
}
.question-wgt__wgt-content-wgt-nav{
  display: flex;
  align-items: center;
}
.question-wgt__wgt-content-wgt-nav-btn{
  background: none;
  border: none;
}
.question-wgt__wgt-content-wgt-nav-btn:first-child{
  margin-right: 15px;
}
.question-wgt__wgt-content-wgt-nav-icon{
  width: 16px;
  height: 17px;
  stroke: #364ed4;
}
